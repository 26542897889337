import React, { Suspense, useEffect, useState } from 'react';
import Header from "./Header";
import Cookies from 'js-cookie';
import { Decryption, isAuthenticated } from "../utils";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateLogInUserDetails, updateUserDetails } from "../redux/auth/action";
import NewHeader from './newHeader';
import NewSidebar from './newSidebar';
import MobileSideBar from './MobileSideBar';
import { commonRouteAdmin } from '../routes';
const Layout = ({ children, history }) => {
    const [isOpenSidebar, setIsOpenSidebar] = useState(true);
    const dispatch = useDispatch()
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [active, setActive] = useState(true);

    let navigate = useNavigate();

    const toggleSidebar = () => {
        const navbarTogglerIcon = document.querySelector(".navbarTogglerIcon svg path");
        setIsOpenSidebar(!isOpenSidebar);
        const cg_mobile_sidebar = document.querySelector(".cg_mobile_sidebar");
        cg_mobile_sidebar?.classList?.toggle("open");

        if (!isOpenSidebar) {
            document.body.classList.add('body-lock');
          } else {
            document.body.classList.remove('body-lock');
          }
    }
    
    const handleLogout = () => {
        setActive('logout')
        localStorage.removeItem('_crowdkure_ad_token')
        Cookies.remove('x-access-token', { path: "/" });
        setIsMenuOpen(false)
        navigate('/');
    };

    const forResize = () => {
        // const sidebar= document.querySelector(".cg_mobile_sidebar");
        if(window.innerWidth < 768){
            // setIsOpenSidebar(false);
            document.body.classList.add('body-lock');
        }
        else{
            setIsOpenSidebar(true);
            document.body.classList.remove('body-lock');
        }
    }

    useEffect(() => {
        if (localStorage.getItem('logedInUser') && isAuthenticated) {
            dispatch(updateLogInUserDetails(Decryption(localStorage.getItem('logedInUser'))))
        }
    }, [isAuthenticated(), localStorage.getItem('logedInUser')]);
    
    useEffect(()=>{
        window.addEventListener("resize", forResize);
    },[])

    return (
        <div className={'layout'}>
            {/* <Header
                isAuthenticated={isAuthenticated()}
                handleLogout={handleLogout}
                setIsMenuOpen={setIsMenuOpen}
                isMenuOpen={isMenuOpen}
                active={active}
            /> */}
            <NewHeader 
                isAuthenticated={isAuthenticated()}
                handleLogout={handleLogout}
                setIsMenuOpen={setIsMenuOpen}
                isMenuOpen={isMenuOpen}
                active={active}
                toggleSidebar={toggleSidebar}
                isOpenSidebar={isOpenSidebar}
            />
            {!window.location.pathname.includes('register') && isAuthenticated() && <MobileSideBar isOpenSidebar={isOpenSidebar}/>}
            <section className={  window.location.pathname.includes('register')? 'inner_wrapper' : isAuthenticated() ? "page_wrapper" : " inner_wrapper"}>

                {/* {
                    isAuthenticated() && <Sidebar
                        history={history}
                    />
                } */}
                 {!window.location.pathname.includes('register') && isAuthenticated() && isOpenSidebar && <NewSidebar/>}
                {
                    isAuthenticated() && !window.location.pathname.includes('specialist-details') &&
                    !window.location.pathname?.includes("change-password") && !window.location.pathname?.includes("settings") && !window.location.pathname.includes('register') ?
                        <Suspense fallback={""}>
                            <div className="content_wrapper">
                                <div className="clinics">
                                    {/*<BreadCrumb />*/}
                                    {children}
                                </div>
                            </div>

                        </Suspense>
                        :
                        <Suspense fallback={""}>
                            {children}
                        </Suspense>
                }
            </section>
        </div>
    )
}
export default Layout