const initialState = {
    groupName: '',
    featuredStatus:[],
    filterDate: {},
    mensionList: {},
    HashTagList: {},
    orderFilter: { date: '' }
}

export default function featuredReducer(state = initialState, { type, payload }) {
    switch (type) {
        case 'FEATURED_DATE_FILTER': {
            return {
                ...state,
                filterDate: payload
            }
        }

        case 'FEATURED_ADD_MENSION':{
            let tempPayload = {...payload.data.data, docs:payload.data.data.docs.reduce((acc,cur)=>{
                return [...acc, {id:cur._id, type:cur.user_type[0].type ,display: cur.user_type[0].type === 'org' ? `@${cur.org_name}` : `@${cur.first_name} ${cur.last_name}`}]
            },[])}
            return {
                ...state,
                mensionList: tempPayload
            }
        }

        case "FILTER_BY_FEATURED_STATUS":{
            return {
                ...state,
                featuredStatus:payload
            }
        }

        case 'FEATURED_ADD_HASHTAG': {
            let temp = {...payload.data.data, docs:payload.data.data.docs.reduce((acc,cur)=>{
                return [...acc, {id:cur._id ,display: cur.name}]
            },[])}         
            return {
                ...state,
                HashTagList : temp
            }
        }
        case 'CLEARFILTERDATA': {
            return {
                ...state,
                groupName: '',
                featuredStatus:[],
                filterDate: {},
                mensionList: {},
                HashTagList: {},
                orderFilter: { date: '' }
            }
        }


        default:
            return state;
    }
}