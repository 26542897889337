import { lazy } from "react";

//privateRouteAdmin

//publicRouteAdmin
const Login = lazy(() => import("../pages/Login"))
const Regestration = lazy(() => import("../pages/Regestration/Regestration"))
const ChangePassword = lazy(()=>import("../pages/ChangePassword"))
const ResetPassword = lazy(()=>import("../pages/ResetPassword/ResetPassword"))

// biz dashboard
const Post = lazy(() => import('../pages/Post'))

// new post
const CreateNewPost = lazy(() => import('../pages/Post/CreateNewPost/CreateNewPost'))
const EditPost = lazy(() => import('../pages/Post/EditPost'))

// group & health communities
const Groups = lazy(() => import('../pages/Groups/Groups'))
const CreateNewGroup = lazy(() => import('../pages/Groups/CreateNewGroup/CreateNewGroup'))
const EditGroup = lazy(() => import('../pages/Groups/EditGroup'))
const GroupFeaturedContent = lazy(() => import('../pages/featuredContent/GroupFeaturedContent/GroupFeaturedContent'))
const featuredContent = lazy(() => import('../pages/featuredContent'))
const CreateNewFeaturedContent = lazy(() => import('../pages/featuredContent/GroupFeaturedContent/GroupFeaturedContent'))
const EditFeaturedContent = lazy(() => import('../pages/featuredContent/EditFeaturedContent'))

export const publicRouteAdmin = [
    { path: '/', Component: Login, title: "Login" },
    { path: '/reset-password',Component: ResetPassword, title: 'Reset Password'},
]

export const privateRouteAdmin = [
    { path: '/post' , Component : Post , title: 'Post'},
    { path: '/post/add' , Component : CreateNewPost , title: 'CreateNewPost'},
    { path: '/post/:id/edit' , Component : EditPost , title: 'EditPost'},
    { path: '/group' , Component : Groups , title: 'Groups'},
    { path: '/group/add' , Component : CreateNewGroup , title: 'CreateNewGroup'},
    { path: '/group/:id' , Component : EditGroup , title: 'EditGroup'},
    { path: '/group-featured-content' , Component : GroupFeaturedContent , title: 'GroupFeaturedContent'},
    { path: '/featured-content' , Component : featuredContent , title: 'featuredContent'},
    { path: '/featured-content/add' , Component : CreateNewFeaturedContent , title: 'CreateNewFeaturedContent'},
    { path: '/featured-content/:id/edit' , Component : EditFeaturedContent , title: 'EditFeaturedContent'},

]

export const commonRouteAdmin = [
    { path: '/register', Component: Regestration, title: 'Regestration'},
]