import postLinkIcon from "../assets/images/post_link_icon.svg";
import groupLinkIcon from "../assets/images/cg_grp_link_icon.svg";
// import groupLinkIcon from "../assets/images/cg_grp_icon.svg";
// import eventLinkIcon from "../assets/images/events_link_icon.svg";
import msgLinkIcon from "../assets/images/msg_link_icon.svg";
import userLinkIcon from "../assets/images/user_link_icon.svg";
import featuredContent from "../assets/images/featured_content.svg";
import eventLinkIcon from "../assets/images/cg_featured_icon.svg";



export const links = [
    {
        id: 1,
        name: "Posts",
        route: "/post",
        icon: postLinkIcon
    },
    {
        id: 2,
        name: "Groups & Health Communities",
        route: "/group",
        icon: groupLinkIcon
    },
    {
        id: 3,
        name: "Featured Content",
        route: "/featured-content",
        icon: featuredContent
    },
    // {
    //     id: 4,
    //     name: "Events",
    //     route: "/events",
    //     icon: eventLinkIcon
    // },
    // {
    //     id: 5,
    //     name: "Messages",
    //     route: "/messages",
    //     icon: msgLinkIcon
    // }
];

export const userLink = [
    // {
    //     id: 1,
    //     name: "Users",
    //     route: "/users",
    //     icon: userLinkIcon
    // }
];