import React from 'react'
import logo from "../../assets/images/New_cancerGo_cms_logo.png";
import { links } from '../../SideBarArray'
import { userLink } from '../../SideBarArray'
import { useNavigate } from 'react-router-dom';

const NewSidebar = ({isOpen}) => {
    const navigate = useNavigate()

    return (
        <>        
            <div className={`cg_newSidebar ${isOpen ? "open" : ""}`}>
                <div className='cg_newSidebar_wrapper'>
                    <div className='cg_newSidebar_links'>
                        <ul>
                            {links.map((item,i) => {
                                return (
                                    <li className={window.location.pathname.includes(item.route) ? 'active' : ''}>
                                        <a onClick={() => navigate(item.route)} className={i == 0 ? "sidebar_first_link" : ""}>
                                            <div className='link_img'>
                                                <img src={item.icon} alt={item.icon} />
                                            </div>
                                            <div className='link_txt'>
                                                <span>{item.name}</span>
                                            </div>
                                        </a>
                                    </li>
                                )
                            })}
                        </ul> 
                    </div>

                    <div className='cg_newSidebar_userlink'>
                        <ul>
                            {userLink.map((item,i) => {
                                return(
                                    <li className={window.location.pathname.includes(item.route) ? 'active' : ''}>
                                        <a onClick={() => navigate(item.route)} className='d_flex align_items_center'>
                                            <div className='link_img'>
                                                <img src={item.icon} alt={item.location} /> 
                                            </div>
                                            <div>
                                                <span>Users</span>
                                            </div>
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>

            {/* <div className={`cg_mobile_sidebar ${isOpenSidebar ? 'open' : ''}`}>
                <div className='cg_newSidebar_wrapper'>
                    <div className="cg_crowdkure_logo">
                        <img src={logo} alt="logo_img" />
                    </div>   
                    <div className='cg_newSidebar_links'>
                        <ul>
                            {links.map((item, i) => {
                                return (
                                    <li className={item.route === window.location.pathname ? 'active' : ''}>
                                        <a onClick={() => navigate(item.route)}>
                                            <div>
                                                <img src={item.icon} alt={item.icon} />
                                            </div>
                                            <div>
                                                <span>{item.name}</span>
                                            </div>
                                        </a>
                                    </li>
                                )
                            })}
                        </ul> 
                    </div>

                    <div className='cg_newSidebar_userlink'>
                        <ul>
                            {userLink.map((item, i) => {
                                return (
                                    <li className={item.route === window.location.pathname ? 'active' : ''}>
                                        <a onClick={() => navigate(item.route)}><img src={item.icon} alt={item.location} />Users</a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default NewSidebar
