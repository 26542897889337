import Cookies from 'js-cookie';
import {Navigate, useLocation} from "react-router-dom";
import React from "react";
import moment from "moment";
import { toast } from 'react-toastify';
const CryptoJS = require('crypto-js');

export const getAccessToken = () => Cookies.get('x-access-token');
export const isAuthenticated = () => !!getAccessToken();

export const Encryption = (data) => {
    try {
        return CryptoJS.AES.encrypt(data, 'DFKTxfMLwjL9WUyNGJYU5200715A').toString();
    } catch (e) {
    }
}

export function Decryption(data) {
    try {
        let bytes =  CryptoJS.AES.decrypt(data, 'DFKTxfMLwjL9WUyNGJYU5200715A');
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (e) {
    }
}

export function RequirePrivateAuth({ children }) {
    let auth = isAuthenticated();
    let location = useLocation();

    if (!auth) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
}

export function RequirePublicAuth({ children }) {
    let auth = isAuthenticated();
    let location = useLocation();

    if (auth) {
        return <Navigate to="/post" state={{ from: location }} replace />;
    }
    return children;
}

export const generateAvatar = (text) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Draw background
    context.fillStyle = stringToHslColor(text);
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw text
    context.font = "bold 65px montserrat_regular";
    context.fillStyle = "#fff";
    context.textAlign = "center";
    context.textBaseline = "middle";

    let first = text?.split(' ')[0]?.charAt(0)?.toUpperCase();
    let last = text?.split(' ')[1]?.charAt(0)?.toUpperCase();

    if (!last) {
        last = text?.split(' ')[0]?.charAt(1)?.toUpperCase() || text?.split(' ')[0]?.charAt(0)?.toUpperCase();
    }
    if (!first) {
        first = "S";
        last = "U";
    }

    context.fillText(first + last, canvas.width / 2, canvas.height / 2);

    return canvas.toDataURL("image/png");
};


export const stringToHslColor = (str, s = 30, l = 80) => {
    let hash = 0;
    for (let i = 0; i < str?.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};


function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
}

export function jsonToFormData(data) {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
}

export const getDuration = (duration, date)=> {
    let seconds = duration.asSeconds();
    let minutes = duration.asMinutes();
    let hours = duration.asHours();
    let days = duration.asDays();
    let months = duration.asMonths();
    let years = duration.asYears();

    if (minutes >= 525600) {
        return `${years.toFixed()} year`
    } else if (months >= 1) {
        return moment(date).format('MMM DD')
    } else if (days < 2) {
        if (seconds < 60) {
            return "now"
        } else if (minutes <= 1) {
            return `${minutes?.toString()?.split('.')[0]} min`
        } else if (minutes > 1 && minutes < 60) {
            return  `${minutes?.toString()?.split('.')[0]} mins`
        } else if (minutes >= 60 && minutes < 120) {
            return `${hours?.toString()?.split('.')[0]} hour`
        } else if (minutes >= 120 && minutes < 1440) {
            return `${hours?.toString()?.split('.')[0]} hours`
        } else {
            return `${days?.toString()?.split('.')[0]} day`
        }
    } else if (days > 1) {
        return `${days?.toString()?.split('.')[0]} days`
    }

}

export function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
}

// export const urlify = (text) => {
//     let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
//     return text?.match(urlRegex)?.[0];
// };

export function urlify(text) {
    // let urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    // let urlRegex = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,5})?(\/.*)?/g;
    let urlRegex = /\b(?:https?:\/\/)?(?:[-\w]+\.)+[a-z]{2,4}(?:\.\w+)*\b/g;
    let result = text?.match(urlRegex)?.[0].replace(urlRegex, function(url,b,c) {
        let RegExp = /^https?:/
        let url2 = !RegExp.test(url) ?  'http://' +url : url;
        // let url2 = (c == 'www.') ?  'http://' +url : url;
        return url2;
    })
    return result;
}

export function getLink(text) {
    // let urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    // let urlRegex = /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,5})?(\/.*)?/g;
    let urlRegex = /\b(?:https?:\/\/)?(?:[-\w]+\.)+[a-z]{2,4}(?:\.\w+)*\b/g;
    let result = text?.match(urlRegex)?.[0].replace(urlRegex, function(url,b,c) {
        //let RegExp = /^https?:/
        //let url2 = !RegExp.test(url) ?  'http://' +url : url;
        // let url2 = (c == 'www.') ?  'http://' +url : url;
        return url;
    })
    return result;
}

export function Success(msg) {
    toast.success(msg, {
        position: "top-right",
        autoClose: 5000,
    })
}

export function Error(msg) {
    toast.error(msg, {
        position: "top-right",
        autoClose: 5000,
    })
}

export function getDescWithId (rowData) {
    if(rowData?.description){
        var description = rowData?.description;
        var descriptionWithId = description;
        var hashtag = rowData?.hashtag;
        var tagPeople = rowData?.tagPeople;
        var hashtagPattern = /#(\w+)/g;
        var a = description?.match(hashtagPattern)
        var dump = [];
        var dump2 = [];

        // Replace mentions with formatted mentions
        for (var i = 0; i < tagPeople.length; i++) {
            var mention = tagPeople[i];
            if(!dump2.includes(mention)){
              dump2.push(mention)
              var mentionId = mention.substring(2, mention.indexOf("|"));
              var mentionName = mention.substring(mention.indexOf("|") + 1, mention.lastIndexOf("|"));
              var mentionPattern = new RegExp("\\" + mention, "g");
              descriptionWithId = descriptionWithId.replaceAll(mentionPattern, "[" + mentionName + "]{" + mentionId + "}");
            }
          
          
        }
        for (var i = 0; i < a?.length; i++) {
            var tag = hashtag.find(ele => ele.includes(a[i]));
            if (tag) {
                var tagId = tag.substring(2, tag.indexOf("|"));
                var tagName = tag.substring(tag.indexOf("|") + 1, tag.length - 1);
                if (!dump.includes(tagName)) {
                    var regex = new RegExp(a[i], "g");
                    descriptionWithId = descriptionWithId.replaceAll(regex, "[" + tagName + "]{" + tagId + "}");
                    dump.push(tagName);
                }
            }
        }
        return descriptionWithId
    }
}

export function getHashtagWithId (rowData) {
    if(rowData?.group_category){
        let hashtagStr = rowData?.group_category?.map((item, i) => {
            return item.split('|')[1].substring(0, item.split('|')[1].length - 1)
        })
        var description = hashtagStr.toString().replace(/[\s,]/g, ' ');
        var descriptionWithId = description;
        var hashtag = rowData?.group_category;
        var hashtagPattern = /#(\w+)/g;
        var a = description?.match(hashtagPattern)
        var dump = [];

        // Replace mentions with formatted mentions
        for (var i = 0; i < a?.length; i++) {
            var tag = hashtag.find(ele => ele.includes(a[i]));
            if(tag){
                var tagId = tag.substring(2, tag.indexOf("|"));
                var tagName = tag.substring(tag.indexOf("|") + 1, tag.length - 1);
                if (!dump.includes(tagName)) {
                    var regex = new RegExp(a[i], "g");
                    descriptionWithId = descriptionWithId.replaceAll(regex, "[" + tagName + "]{" + tagId + "}");
                    dump.push(tagName);
                }
            }
        }
        return descriptionWithId
    }
}