import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux';
import viewAsprofilePic from "../../../assets/images/view_as_profile_pic.svg";
import { changeAsOrg, changeAsUser } from '../../../redux/auth/action';
import { Decryption, generateAvatar } from '../../../utils';

const SwitchProfile = ({orgData,closeDropDown,isOpen,setIsOpen,currentUser}) => {
    const dispatch = useDispatch()
    const viewRef = useRef();

    useEffect(() => {
        const checkOutSideClick = e => {
            if (isOpen && viewRef.current && !viewRef.current.contains(e.target) && e.target.getAttribute('dataId') !== 'ViewAsIcon') { setIsOpen(false) }
        }
        document.addEventListener("mousedown", checkOutSideClick)
        return () => { document.removeEventListener("mousedown", checkOutSideClick) }
    }, [isOpen])

    const handleSwitchUser = (user) => {
        let payload = {
            org_id: user._id 
        }
        dispatch(user?.user_type[0]?.type === 'org' ? changeAsOrg(payload) : changeAsUser()).then(res => {
            if (res.data.success) {
                closeDropDown()
            }
        })
    }

    return (
        <div className="cg_switch_profile_modal position_absolute" ref={viewRef}>
            <div className='cg_switch_profile_modal_wrapper'>
                {orgData?.list?.length > 0 && orgData?.list?.map((item,i) => {
                    return(
                        <div className="cg_switch_profle_modal_content">
                            <div className={` ${item?._id === currentUser?._id ? '' : 'cursor_pointer'}`} onClick={() => item?._id !== currentUser?._id  ? handleSwitchUser(item) : ''}>
                                <div className='d_flex justify_content_between align_items_center cg_switch_profle_modal_content_width'>
                                <div className='d_flex align_items_center'>
                                    <div className='cg_modal_profile_pic'>
                                        <img className={item?._id === currentUser?._id ? 'active' : ''} src={item?.profile ? item?.profile : generateAvatar(item.org_name ? item.org_name : item?.first_name + ' ' + item?.last_name)} alt='viewAsprofilePic' />
                                    </div>
                                    <div className='cg_modal_profile_content'>
                                        <div className='cg_modal_profile_content_name'>
                                            <h4 className='cg_user_name'>{item && item?.user_type && item?.user_type[0]?.isDoctor ? 'Dr.' : ''}{item.org_name ? item.org_name : item?.first_name + ' ' + item?.last_name}</h4>
                                        </div>
                                        <div className='cg_modal_profile_location'>
                                            <span className='cg_user_location'>{item?.address}</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='cg_profile_badgeCnt'>
                                        <p>{item?.badgeCount !== 0 && item?.badgeCount}</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default SwitchProfile