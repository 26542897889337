import { RequirePrivateAuth, RequirePublicAuth } from "../../utils";
import { Route, useNavigate } from "react-router-dom";
import React from "react";
import { privateRouteAdmin } from "../../routes";

const PrivateRoute = () => {
    let navigate = useNavigate();

    return (
        privateRouteAdmin?.map(({ path, Component, title }, key) => {
            return (
                <Route
                    exact
                    path={path}
                    element={<RequirePrivateAuth><Component navigate={navigate}/></RequirePrivateAuth>}
                    key={key}
                />
            )
        })
    );
};
export default PrivateRoute