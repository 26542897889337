import React from "react";
import Layout from "./layout";
import { Routes, Route } from "react-router-dom";
import PublicRouter from "./hoc/PublicRouter";
import PrivateRoute from "./hoc/PrivateRouter";
import NotFound from "./pages/NotFound";
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; 
import CommonRouter from "./hoc/CommonRouter";
import 'react-tooltip/dist/react-tooltip.css';
import "video-react/dist/video-react.css";

const App = () => {
    return (
        <Layout >
            <Routes>
                {PublicRouter()}
                {PrivateRoute()}
                {CommonRouter()}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Layout>
    )
}

export default App
