
const initialState = {
    groupType: '',
    groupStatus:'',
    filterDate: {},
    HashTagList:{},
    orderFilter:{date:'',member:'',moderator:''}
}

export default function groupReducer(state = initialState, { type, payload }) {
    switch (type) {
        case 'FILTER_BY_GROUP_TYPE': {
            return {
                ...state,
                groupType: payload
            }
        }
        case 'FILTER_BY_GROUP_STATUS': {
            return {
                ...state,
                groupStatus: payload
            }
        }
        case 'GROUP_DATE_FILTER': {
            return {
                ...state,
                filterDate: payload
            }
        }
        case 'GROUP_ORDER_FILTER': {
            return {
                ...state,
                orderFilter: payload
            }
        }

        case 'GROUP_ADD_HASHTAG': {
            let temp = {...payload.data.data, docs:payload.data.data.docs.reduce((acc,cur)=>{
                return [...acc, {id:cur._id ,display: cur.name}]
            },[])}            
            return {
                ...state,
                HashTagList : temp
            }
        }
        case 'CLEARFILTERDATA': {
            return {
                ...state,
                groupType: '',
                groupStatus:'',
                filterDate: {},
                orderFilter:{date:'',members:'',moderators:''}
            }
        }

        default:
            return state;
    }
}