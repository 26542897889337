import { combineReducers } from 'redux';
import loginReducer from '../auth/reducer';
import postReducer from '../post/reducer';
import groupReducer from '../group/reducer';
import featuredReducer from '../featureContent/reducer';

export default combineReducers({
 loginReducer,
 postReducer,
 groupReducer,
 featuredReducer,
})