import React from 'react'
import logo from "../../assets/images/New_cancerGo_cms_logo.png";
import { links } from '../../SideBarArray'
import { userLink } from '../../SideBarArray'
import { useNavigate } from 'react-router-dom';

const MobileSideBar = (isOpenSidebar) => {
    const navigate = useNavigate()
    return (
        <>
            <div className={`cg_mobile_sidebar ${isOpenSidebar ? 'open' : ''}`}>
                    <div className='cg_newSidebar_wrapper'>
                        <div className="cg_crowdkure_logo">
                            <img src={logo} alt="logo_img" />
                        </div>   
                        <div className='cg_newSidebar_links'>
                            <ul>
                                {links.map((item, i) => {
                                    return (
                                        <li className={window.location.pathname.includes(item.route) ? 'active' : ''}>
                                            <a onClick={() => navigate(item.route)}>
                                                <div className='link_img'>
                                                    <img src={item.icon} alt={item.icon} />
                                                </div>
                                                <div className='link_txt'>
                                                    <span>{item.name}</span>
                                                </div>
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul> 
                        </div>

                        <div className='cg_newSidebar_userlink'>
                            <ul>
                                {userLink.map((item, i) => {
                                    return (
                                        <li className={window.location.pathname.includes(item.route) ? 'active' : ''}>
                                            <a onClick={() => navigate(item.route)} className='align_items_center'>
                                                <div className='link_img'>
                                                    <img src={item.icon} alt={item.location} />
                                                </div>
                                                <div>
                                                    <span>Users</span>
                                                </div>
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default MobileSideBar