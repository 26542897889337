
const initialState = {
    groupName: [],
    filterDate: {},
    mensionList:{},
    HashTagList:{},
    orderFilter:{date:''}
}

export default function postReducer(state = initialState, { type, payload }) {
    switch (type) {
        case 'FILTER_BY_GROUP_NAME': {
            return {
                ...state,
                groupName: payload
            }
        }
        case 'POST_DATE_FILTER': {
            return {
                ...state,
                filterDate: payload
            }
        }
        case 'POST_ORDER_FILTER': {
            return {
                ...state,
                orderFilter: payload
            }
        }
        case 'POST_ADD_MENSION':{
            let tempPayload = {...payload.data.data, docs:payload.data.data.docs.reduce((acc,cur)=>{
                return [...acc, {id:cur._id, type:cur.user_type[0].type ,display: cur.user_type[0].type === 'org' ? `@${cur.org_name}` : `@${cur.first_name} ${cur.last_name}`}]
            },[])}
            return {
                ...state,
                mensionList: tempPayload
            }
        }

        case 'POST_ADD_HASHTAG': {
            let temp = {...payload.data.data, docs:payload.data.data.docs.reduce((acc,cur)=>{
                return [...acc, {id:cur._id ,display: cur.name}]
            },[])}            
            return {
                ...state,
                HashTagList : temp
            }
        }
        case 'CLEARFILTERDATA': {
            return {
                ...state,
                groupName: [],
                filterDate: {},
                orderFilter:{date:''}
            }
        }

        default:
            return state;
    }
}