import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { Route, useNavigate } from "react-router-dom";
import { commonRouteAdmin } from "../../routes";

const CommonRouter = () => {
    let navigate = useNavigate();

    return (
        commonRouteAdmin.map(({ path, Component, title }, key) => {
            return (
                <Route
                    exact
                    path={path}
                    element={<Component navigate={navigate}/>}
                    key={key}
                />
            )
        })
    )
}
export default CommonRouter