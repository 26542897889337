import React,{useEffect, useRef, useState} from 'react'
import logo from "../../assets/images/New_cancerGo_cms_logo.png";
import viewAsprofilePic from "../../assets/images/view_as_profile_pic.svg";
import profile_pic from './../../assets/images/profile_pic.png';
import openCloseIcon from "../../assets/images/accrodian_arrow.svg";
import cgProfileImg from "../../assets/images/cg_profile_img.png";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClose, faTimes } from '@fortawesome/free-solid-svg-icons';
import SwitchProfile from './SwitchProfile';
import { switchProfileList, updateLogInUserDetails, updateUserDetails } from '../../redux/auth/action';
import { Decryption, Encryption, generateAvatar } from '../../utils';

const NewHeader = ({ isAuthenticated, handleLogout, isMenuOpen, setIsMenuOpen, active,toggleSidebar,isOpenSidebar }) => {
    
    const ref = useRef();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state?.loginReducer?.currentUser)
    const logInUser = useSelector(state => state?.loginReducer?.logInUser)
    const [orgData, setOrgData] = useState({ list: []});
    let match = null;
    let password = null;
    
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        let findcurrentUser = orgData?.list.filter((item => item._id === currentUser?._id))
        let findUser = orgData?.list.filter((item => item._id === logInUser?._id))

        if(findcurrentUser.length > 0 ){
            localStorage.setItem("userDetail", Encryption(JSON.stringify(findcurrentUser[0])))
        }
        if(findUser.length > 0){
            localStorage.setItem("logedInUser", Encryption(JSON.stringify(findUser[0])))
        }
    }, [orgData])
    

    useEffect(() => {
        if (localStorage.getItem('userDetail') && isAuthenticated) {
            dispatch(updateUserDetails(Decryption(localStorage.getItem('userDetail'))))
        }
    }, [isAuthenticated, localStorage.getItem('userDetail')]);

    useEffect(() => {
        if (localStorage.getItem('logedInUser') && isAuthenticated) {
            dispatch(updateLogInUserDetails(Decryption(localStorage.getItem('logedInUser'))))
        }
    }, [isAuthenticated, localStorage.getItem('logedInUser')]);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(switchProfileList()).then(res => {
                if (res.data.success) {
                    setOrgData({
                        ...orgData,
                        list: res?.data?.data?.profiles,
                    })
                }
            })
        }
    }, [isAuthenticated])
    
    
    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target) && e.target.getAttribute('id') !== 'profileIcon') { setIsMenuOpen(false) }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => { document.removeEventListener("mousedown", checkIfClickedOutside) }
    }, [isMenuOpen])

    const settingScreenData = () => {
        setIsMenuOpen(false)
        setIsOpen(false)
    }

    return (
        <>
        <header>
            {isAuthenticated && !window.location.pathname.includes('register') &&
            <div className='header cg_new_header'>
                <div className='cg_header_wrapper'>
                    <div className='d_flex'>
                    <div className='cg_website_logo'>
                        <div className="cg_crowdkure_logo">
                            <img src={logo} alt="logo_img" />
                        </div>
                    </div>
                    <div className='cg_header_contents'>
                        <p className='view_as'>Viewing as</p>
                        <div className='cg_views_profile d_flex justify_content_between align_items_center'>
                            <div className='cg_view_as d_flex align_items_center position_relative'>
                                <div className={`cg_view_as_profile ${(orgData?.list?.length - 1) > 0 ? 'cursor_pointer' : ''}`}>
                                    <img src={currentUser?.profile ? currentUser?.profile : generateAvatar(currentUser?.org_name ? currentUser?.org_name : currentUser?.first_name + ' ' + currentUser?.last_name)} alt='viewAsprofilePic' onClick={() => (orgData?.list?.length - 1) > 0 ? toggleModal() : ''} dataId='ViewAsIcon'/>
                                </div>
                                <div className='cg_view_as_profile_content'>
                                    <h5>{currentUser && currentUser?.user_type && currentUser?.user_type[0]?.isDoctor ? 'Dr.' : ''}{currentUser?.org_name ? currentUser?.org_name : currentUser?.first_name + ' ' + currentUser?.last_name}</h5>
                                    <span>{currentUser?.address}</span>
                                </div>
                                {(orgData?.list?.length - 1) > 0 && <div className='cg_open_close_icon' onClick={() => toggleModal()} dataId='ViewAsIcon'>
                                    <img src={openCloseIcon} alt="openCloseIcon" className={isOpen ? 'arrow rotate' : 'arrow'} onClick={() => toggleModal()} dataId='ViewAsIcon'/>
                                </div>}
                                {isOpen && <SwitchProfile orgData={orgData} closeDropDown={toggleModal} isOpen={isOpen} setIsOpen={setIsOpen} currentUser={currentUser}/>}
                            </div>
                            <div className='cg_profile_details'>
                                <div className='d_flex align_items_center '>
                                    <div className='cg_profile_userName'>
                                        <h4>{logInUser && logInUser?.user_type && logInUser?.user_type[0]?.isDoctor ? 'Dr.' : ''}{logInUser?.org_name ? logInUser?.org_name : logInUser?.first_name + ' ' + logInUser?.last_name}</h4>
                                    </div>
                                    <div className='cg_profile_img'>
                                        <div className='cg_profile_img_content'>
                                            <img onClick={() => setIsMenuOpen(!isMenuOpen)} src={logInUser?.profile ? logInUser?.profile : generateAvatar(logInUser?.org_name ? logInUser?.org_name : logInUser?.first_name + ' ' + logInUser?.last_name)} id="profileIcon" alt="cgProfileImg" />
                                        </div>
                                        {/* <div className="cursor_pointer" ><img id="profileIcon" onClick={() => setIsMenuOpen(!isMenuOpen)} src={down_arrow} alt="down_arrow" /></div> */}
                                    </div>
                                    <div className='cg_open_close_icon'onClick={() => setIsMenuOpen(!isMenuOpen)} id="profileIcon">
                                        <img onClick={() => setIsMenuOpen(!isMenuOpen)} src={openCloseIcon} id="profileIcon" alt="openCloseIcon" className={isMenuOpen ? "arrow rotate" : "arrow"}/>
                                    </div>
                                    {console.log(isMenuOpen,"isMenuOpen")}
                                    <div className="drop_down">
                                        {
                                            isMenuOpen &&
                                            <ul className="list_unstyled drop_down_list" ref={ref}>
                                                {/* <li><Link to="/change-password" className={window.location?.pathname === '/change-password' && "active"} onClick={() => settingScreenData()}> Change Password <span className="dot_blue d_none" /></Link></li> */}
                                                {/*<li><Link to="/settings" className={window.location?.pathname === '/settings' && "active"} onClick={() => settingScreenData()}>Settings <span className="dot_blue d_none" /></Link></li>*/}
                                                {/* <li><a href="#" >Contact Support <span className="dot_blue d_none" /></a></li> */}
                                                <li><a className={active === 'logout' && " "} onClick={() => handleLogout()}>Log Out<span className="dot_blue d_none" /></a></li>
                                            </ul>
                                        }
                                    </div>
                                    <button onClick={toggleSidebar} className='navbarTogglerIcon'><FontAwesomeIcon className="navbarTogglerIcon" icon={isOpenSidebar ? "": faBars}/></button>
                                    <div
                                        className={`layer ${isOpenSidebar ? "visible" : ""}`}
                                        onClick={toggleSidebar}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>}
        </header>
        </>
    )
}

export default NewHeader
