import { LOGIN_SUCCESS,SWITCH_USER_SUCCESS, USER_SUCCESS_POPUP } from '../../actionTypes';
const initialState = {
    currentUser: {},
    logInUser: {},
    popupToggle: false,
    popupData:false
}

export default function loginReducer(state = initialState, { type, payload }) {
    switch (type) {
        case SWITCH_USER_SUCCESS: {
            return {
                ...state,
                currentUser: payload
            }
        }
        case LOGIN_SUCCESS: {
            return {
                ...state,
                logInUser: payload
            }
        }
        case USER_SUCCESS_POPUP: {
            console.log('payload',payload);
            return {
                ...state,
                popupToggle:payload.toggle,
                popupData:payload.popupData,
            }
        }
        default:
            return state;
    }
}